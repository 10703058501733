import MobileMenu from './MobileMenu.svelte';
import { menuService } from './lib/service';

export default function initMobileMenu(selector = '.offcanvas-body') {
    let instance;
    let menu;

    document.addEventListener('show.bs.offcanvas', () => {
        if (!menu) {
            menu = menuService();
        }

        if (instance) {
            instance.$destroy();
        }
        instance = new MobileMenu({
            target: document.querySelector(selector),
            props: {
                menu,
            },
        });
    });
}
