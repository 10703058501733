export default function initHeaderFooter() {
    document.querySelectorAll('#header').forEach((header) => {
        header.classList.remove('container');
        header
            .querySelector('.global-header')
            ?.classList.toggle('container', false);
        header.querySelector('#nav')?.classList.toggle('container', false);
        header
            .querySelector('.cf-promo.cf-logo')
            ?.classList.toggle('container', true);
    });

    document.querySelectorAll('#footer').forEach((footer) => {
        const footerBottom = footer.querySelector('.container-footer--bottom');
        const container = document.createElement('div');

        footer.classList.remove('container');
        footer
            .querySelector('.cf-promo.cf-logo')
            ?.classList.remove(...['col-12', 'col-sm-6', 'col-md-4']);

        footerBottom
            ?.querySelectorAll('.standard-menu.footer-menu')
            .forEach((el) => {
                el.classList.remove(
                    ...['col-md-12', 'col-lg-9', 'col-lg-3', 'float-lg-right']
                );
            });

        footer
            .querySelector('.container-footer--top > .component')
            ?.classList.add('container');

        footerBottom?.classList.remove('container');
    });
}
