import { JSRenderTemplatesMixin } from '@cfed/site-search/mixins';
import templates from './templates';
@JSRenderTemplatesMixin
export class FSBTemplateService {
    emptyText(props) {
        if (
            props.isNullSearch &&
            props.showNullSearchText &&
            props.nullSearchText != ''
        ) {
            return props.nullSearchText;
        } else if (props.noResultsText != '') {
            return props.noResultsText;
        }
        return '';
    }

    formatTime(value) {
        return new Intl.DateTimeFormat('en-us', {
            hour: 'numeric',
            minute: 'numeric',
            timeZoneName: 'short',
            timeZone: 'America/New_York',
        }).format(Date.parse(value));
    }

    formatDate(value) {
        const myDate = Date.parse(value),
            parts = new Intl.DateTimeFormat('en-us', {
                year: 'numeric',
                month: 'long',
                day: '2-digit',
                timeZone: 'America/New_York',
            }).formatToParts(myDate);
        return parts[0].value + ' ' + parts[2].value + ', ' + parts[4].value;
    }

    async render(props) {
        await this.getJSTemplates(templates);

        return (el) => {
            el.innerHTML = this.renderJSTemplate('resultList', props, {
                formatDate: this.formatDate,
                formatTime: this.formatTime,
                emptyText: () => this.emptyText(props),
            });
        };
    }
}
