export function getSubMenu(container) {
    const menu = [];
    let title;

    for (const el of container.children) {
        switch (el.tagName) {
            case 'SPAN':
                title = el.innerText.trim();
                break;
            case 'A':
                return {
                    title: el.innerText.trim(),
                    url: el.href,
                };
            case 'LI':
                menu.push(getSubMenu(el));
                break;
            case 'UL':
                return {
                    title,
                    items: [...getSubMenu(el)],
                };
            default:
        }
    }
    return menu;
}

export function menuService(selector = '.offcanvas-body template') {
    const menu = [];
    const [root] = document.querySelector(selector).content.children;

    for (const el of root.children) {
        menu.push(getSubMenu(el));
    }

    return menu;
}
